var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["row", _vm.position + "-team"] }, [
    _c(
      "div",
      {
        staticClass:
          "col-3 d-flex align-items-center justify-content-start team-name"
      },
      [
        _c(
          "div",
          { staticClass: "d-flex align-items-center ml-2 mb-2" },
          [
            _c(
              "router-link",
              {
                staticClass: "h5 mt-1 ml-2",
                attrs: { to: "/team/" + _vm.team_id }
              },
              [
                _c("TeamLogo", {
                  attrs: { src: _vm.icon_url, team_name: _vm.team_name }
                }),
                _vm._v(" " + _vm._s(_vm.team_name) + " ")
              ],
              1
            )
          ],
          1
        ),
        _c("span", [_vm._v("BANK:")])
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "col-9 rounds d-flex align-items-stretch justify-content-start"
      },
      _vm._l(_vm.rounds, function(round, i) {
        return _c(
          "div",
          {
            key: i,
            class: [
              "eco-box",
              "d-flex",
              "justify-content-center",
              "align-items-center",
              round.win ? "win" : "",
              _vm.role
            ]
          },
          [
            _vm._v(" " + _vm._s(round.eco) + " "),
            _c("span", [_vm._v(_vm._s(round.loadout / 1000) + "k")])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }