var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BarChart", {
    attrs: { "chart-data": _vm.chartData, options: _vm.chartOptions }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }